import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 2rem;

        @media ${theme.breakpoints.md.min} {
            padding-bottom: 3.2rem;
        }
    `}
`;
